@import 'style/misc-helpers';

.trigger {
	cursor: pointer;
	user-select: none;

	&_checkable {
		user-select: none;
	}

	&[data-is-checkable='true'] {
		cursor: default;
	}

	&[data-checkable-mode='inline'] {
		margin-bottom: 0 !important;
	}
}

.target {
	max-height: 600px; // @todo: make dynamic
	overflow: hidden;
	// margin-left: var(--space-neg-xs) !important;
	// margin-right: var(--space-neg-xs) !important;
	// padding-left: var(--space-xs) !important;
	// padding-right: var(--space-xs) !important;
	transition: all 0.5s var(--ease-in-out-quart);

	&[aria-hidden='true'] {
		max-height: 0;
		opacity: 0;
	}

	&[data-checkable-mode='inline'] {
		margin: 0 !important;
		padding-left: calc(2 * var(--space-m));

		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}
}
