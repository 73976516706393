@import 'style/variables';

.root {
}

.products_nav {
	display: none;

	@media (min-width: $col-m) {
		display: block;
	}
}
