@import './includes.scss';

// Box sizing
*,
*::before,
*::after {
	box-sizing: border-box;
}

// Focus
:focus {
	outline: 3px solid var(--color-poppy);
	outline-offset: var(--space-xxxs);
	transition: all 0.2s var(--ease-in-out-quart);
}

.js-focus-visible :focus:not(.focus-visible) {
	outline: 0;
}

::-moz-focus-inner {
	border: 0;
	outline: 0;
}

// Base elements
body {
	overflow-x: hidden;
	margin: 0;
	color: var(--color-black);
	background: url(media/bg.png) no-repeat center 10vh fixed;

	// Dark mode
	/* @media (prefers-color-scheme: dark) {
		background-color: var(--color-black);
		color: #ddd;
	} */

	a {
		@include link;
	}
}

#root {
	// overflow-x: hidden; // Fucks with pos sticky
	min-height: 100vh;
	padding-bottom: var(--space-xxl);
}

#{$heading} {
	max-width: $col-l;

	&::first-letter {
		text-transform: uppercase;
	}
}

#{$text-els} {
	color: var(--text-color);

	/* @media (prefers-color-scheme: dark) {
		color: #ddd;
	} */
}

p,
dd {
	@include space(s);
	max-width: $col-m;
}

h1 {
	margin-top: var(--space-xxxl);
}

h2 {
	margin-top: var(--space-xxl);
}

h3 {
	margin-top: var(--space-xl);
}

h4 {
	margin-top: var(--space-l);
}

::selection {
	background-color: var(--color-red);
	color: var(--color-white);
}
