@import 'style/variables';
@import 'style/typography-helpers';

.root {
	background-color: var(--color-storm20);
	padding: var(--space-m);
	border-radius: var(--radius);
}

.header {
	display: flex;
	justify-content: space-between;

	h2 {
		@include heading-4;
		margin: 0;
	}
}

.trigger {
	transition: all 0.2s var(--ease-in-out-quart);

	&_icon {
		transform: rotate(-90deg);
		transition: all 0.5s var(--ease-in-out-back);
		width: var(--space-l);
		height: var(--space-l);

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&[aria-expanded='false'] {
		.trigger_icon {
			transform: rotate(90deg);
		}
	}

	#{$interact} {
		transform: scale(1.1);
	}
}
