@import 'style/typography-helpers';

table {
	td,
	th {
		padding: var(--space-xxs) 0;
		text-align: right;
		font-weight: normal;

		&:first-child {
			text-align: left;
		}
	}

	thead {
		th {
			@include small-copy;
			color: var(--text-color);
		}
	}

	tbody {
		tr {
			td,
			th {
				border-top: 1px solid var(--color-storm--alpha50);
			}
		}
	}
}
