@keyframes wraparound-lr {
	50% {
		transform: translateX(50%);
		opacity: 0;
	}

	51% {
		transform: translateX(-50%);
	}
}

@keyframes wraparound-rl {
	50% {
		transform: translateX(-50%);
		opacity: 0;
	}

	51% {
		transform: translateX(50%);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
