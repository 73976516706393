@import 'style/variables';
@import 'style/layout-helpers';

// Columns
@each $size in (xxs xs s m l xl xxl) {
	[data-col='#{$size}'] {
		max-width: var(--col-#{$size});
	}
}

// Restrainer
@each $size in (xs s m l xl max full) {
	[data-restrain='#{$size}'] {
		max-width: var(--col-#{$size});
		margin-left: auto;
		margin-right: auto;
		padding-left: var(--space-m);
		padding-right: var(--space-m);

		@media (min-width: $col-s) {
			padding-left: var(--space-m);
			padding-right: var(--space-m);
		}

		@media (min-width: $col-m) {
			padding-left: var(--space-l);
			padding-right: var(--space-l);
		}

		@media (min-width: $col-l) {
			padding-left: var(--space-xl);
			padding-right: var(--space-xl);
		}
	}
}

// Gutters
@each $size in (xxxs xxs xs s m l xl xxl xxxl) {
	[data-gap='#{$size}'] {
		@include gap($size);
	}
}

// Vertical spacing
@each $size in (xxxs xxs xs s m l xl xxl xxxl) {
	[data-space='#{$size}'] {
		@include space($size);

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Content box
[data-contentbox] {
	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

// Flexgrid
@each $size in (xxxs xxs xs s m l xl xl) {
	[data-flexgrid='#{$size}'] {
		@include flexgrid(var(--col-#{$size}));
	}
}
