@import 'style/variables';
@import 'style/typography-helpers';

html {
	@include body-copy;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#{$heading} {
	line-height: normal;
}

h1 {
	@include heading-1;
}

h2 {
	@include heading-2;
}

h3 {
	@include heading-3;
}

h4,
h5,
h6 {
	@include heading-4;
}
