// Gutters
@mixin gap($size) {
	margin: var(--space-neg-#{$size});

	> * {
		margin: var(--space-#{$size});
	}
}

// Spacing
@mixin space($size) {
	margin-top: var(--space-#{$size});
	margin-bottom: var(--space-#{$size});
}

// Simple flexgrid
@mixin flexgrid($size) {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax($size, 1fr));
}
