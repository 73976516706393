@import 'style/variables';
@import 'style/layout-helpers';
@import 'style/typography-helpers';

.root {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: space-between;
	padding: var(--space-s) 0;

	@media (min-width: $col-l) {
		flex-flow: row;
	}
}

.branding {
	display: flex;
	flex-flow: column;
	align-items: center;

	@media (min-width: $col-l) {
		flex-flow: row;
	}

	> p {
		@include heading-2;
		margin-left: var(--space-m);
	}
}

.logo {
	height: 179px;
	max-width: 180px;
	display: block;

	@media (min-width: $col-s) {
		max-width: none;
	}
}
